import { graphql } from "gatsby";
import SEO from "../../../../src/components/SEO";
import Address from "../../../../src/components/Address";
import Telephone from "../../../../src/components/Telephone";
import * as React from 'react';
export default {
  graphql,
  SEO,
  Address,
  Telephone,
  React
};